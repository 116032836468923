import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Layout from '~/components/Layout';
import TextCard from '~/components/molecules/TextCard';

const NotFoundPage = () => {
  const content = {
    heading: {
      text: 'Oops you look lost!',
      type: 'h2',
    },
    subheading: '404',
    content: "This probably isn't the page you're looking for",
    buttons: [
      {
        button: {
          button: {
            button: {
              title: 'Return home',
              url: '/',
              target: '',
            },
          },
          type: 'textLink',
        },
      },
    ],
  };

  return (
    <Layout>
      <section className="my-20 mt-36 md:my-36 ">
        <div className="container">
          <div className="flex flex-col md:flex-row items-center md:justify-between md:min-h-[40rem]">
            <div className="relative mb-12 md:w-1/2 md:mb-0">
              <StaticImage src={'../assets/images/404.png'} className="" alt="" />
              <div className="hidden md:block"></div>
            </div>
            <div className="md:w-5/12">
              <TextCard {...content} className="relative z-10" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
